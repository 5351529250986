import React from "react"

// Components
import Seo from "../components/seo"
// Main Components
import Header from "../components/funnels/header"
// import Hero from "../components/funnels/newsletter/hero"
import Footer from "../components/funnels/footer"

const NewsletterPage = () => {
  return (
    <>
      <Header />
      <Seo title="Prijavi se na Newsletter" lang="sl" />
      {/* <Hero /> */}
      <div className="bg-gray-200 h-96"></div>
      <div className="bg-gray-200 h-96"></div>
      <div className="bg-gray-200 h-96"></div>
      <div className="bg-gray-200 h-96"></div>
      <Footer />
    </>
  )
}

export default NewsletterPage
