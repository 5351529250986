import React from "react"

// Assets
import LogoIcon from "../../images/icons/logo.inline.svg"

const Header = () => {
  return (
    <div
      className="py-3 bg-white border-b text-center flex items-center justify-center"
      aria-label="Lili Cadez"
    >
      <LogoIcon />
    </div>
  )
}

export default Header
