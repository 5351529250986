import React from "react"
import { Link } from "gatsby"

const Footer = ({ mt }) => {
  return (
    <footer className={`py-4 border-t ${!mt && "mt-8"}`}>
      <div className="px-4 flex items-center justify-between max-w-screen-xl mx-auto flex-col text-center">
        <div className="text-sm">
          <Link to="/terms-of-use" className="hover:underline font-semibold">
            Splošni pogoji
          </Link>
        </div>
        <span className="text-sm mt-1">
          ©Lili Cadez 2022 - Vse pravice pridržane
        </span>
      </div>
    </footer>
  )
}

export default Footer
